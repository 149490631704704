<template>
  <div class="changepwd">
    <!-- 头 -->
    <!-- <div class="header">
      <div class="imgs">
        <img src="../assets/logo1.png" alt />
      </div>
      <div class="title">
        <span>健康数据管理中心</span>
      </div>
    </div> -->
    <div class="main">
      <div class="forget">找回密码</div>
      <el-form class="pwdform" :model="form" label-position="top">
        <el-form-item label="账号" :label-width="formLabelWidth">
          <el-input
            v-model="form.phone"
            placeholder="请输入需要找回的账号"
            :disabled="InputShow"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth">
          <template #label>
            <span class="title-style-1">验证码</span>
            <span v-if="tellShow" class="title-style-2"
              >*请查收{{
                tellText.toString().replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
              }}手机号的验证码</span
            >
          </template>
          <el-input
            class="yzm"
            v-model="form.code"
            autocomplete="off"
          ></el-input>
          <el-button class="yzmbut" @click="obtain" :disabled="btnTrue">{{ content }}</el-button>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.password"
            placeholder="请输入新密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入新密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.pwd"
            placeholder="重复输入新密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="but">
        <el-button type="primary" @click="determine">确定</el-button>
        <el-button type="primary" @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phone: "",
        code: "",
        password: "",
      },
      content: "发送验证码",
      totalTime: 60,
      canClick: true, //添加canClick
      verificationcode: {
        phone: "",
      },
      formLabelWidth: "120px",
      tellText: "",
      tellShow: false,
      btnTrue:false,
      InputShow:false,
    };
  },
  methods: {
    // 取消
    cancel() {
      // this.$router.replace("/");
      this.$router.replace("/AlarmApiManage");
    },
    // 获取验证码
    obtain() {
      // console.log(111)
      var that = this;
      this.tellText = "";
      this.tellShow = false;
      // if (!/^1[3456789]\d{9}$/.test(that.form.phone)) {
      //   that.$message.error("手机号格式不正确");
      //   return false;
      // }
      that
        .$http({
          url: "/foo/login/checkUser",
          params: { phone: that.form.phone },
          method: "POST",
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            // 找回密码
            that
              .$http({
                url: "/foo/login/sendPhoneCode",
                params: { phone: that.form.phone },
                method: "POST",
              })
              .then((res) => {
                //验证数据是否获取到
                console.log(res);
                if (res.data.code == 200) {
                  that.$message({
                    message: "验证码已发送",
                    type: "success",
                  });
                  if (res.data.result) {
                    this.tellText = res.data.result;
                    this.InputShow = true;
                    this.tellShow = true;
                  }
                  if (!that.canClick) return; //改动的是这两行代码
                  that.canClick = false;
                  that.content = that.totalTime + "s后重新发送";
                  that.btnTrue = true;
                  let clock = window.setInterval(() => {
                    that.totalTime--;
                    that.content = that.totalTime + "s后重新发送";
                    that.btnTrue = true;
                    if (that.totalTime < 0) {
                      window.clearInterval(clock);
                      that.content = "重新发送验证码";
                      that.btnTrue = false;
                      that.totalTime = 60;
                      that.canClick = true; //这里重新开启
                    }
                  }, 1000);
                } else {
                  that.$message.error("验证码发送失败");
                  // this.InputShow = false;
                }
              });
          } else {
            that.$message.error(res.data.msg);
            this.InputShow = false;
          }
        });
    },
    // 确定
    determine() {
      var that = this;
      //  修改密码
      that
        .$http({
          url: "/foo/login/updatePassWord",
          params: that.form,
          method: "POST",
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "密码修改成功",
              type: "success",
            });
            // this.$router.replace("/");
            this.$router.replace("/AlarmApiManage");
          } else {
            this.$message.error("请输入正确的验证码");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="less">
.title-style-2 {
  font-size: 12px;
  color: red;
  margin-left: 3%;
}
.changepwd {
  .header {
    margin-top: 100px;
    .imgs {
      text-align: center;
      img {
        width: 356px;
        height: 66px;
      }
    }
    .title {
      font-size: 40px;
      text-align: center;
      color: #91aca7;
      margin-top: 40px;
      letter-spacing: 8px;
      span {
        font-family: "ExtraLight";
      }
    }
  }
  .main {
    width: 500px;
    margin: 0 auto;
    .forget {
      font-size: 34px;
      text-align: center;
      color: #a8a8a8;
      margin-top: 40px;
    }
    .el-form {
      margin-top: 40px;
      .el-form-item__label {
        font-size: 18px;
        color: #a8a8a8;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
      .yzm {
        width: 200px;
      }
      .yzmbut {
        margin-left: 20px;
      }
    }
    .but {
      text-align: right;
      margin-top: 60px;
      .el-button {
        height: 50px;
        width: 100px;
        background: #0085d0;
        font-size: 18px;
        border: none;
        border-radius: 33px;
      }
    }
  }
}
</style>