import { render, staticRenderFns } from "./Retrievepassword.vue?vue&type=template&id=92a990e8"
import script from "./Retrievepassword.vue?vue&type=script&lang=js"
export * from "./Retrievepassword.vue?vue&type=script&lang=js"
import style0 from "./Retrievepassword.vue?vue&type=style&index=0&id=92a990e8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports